import Contrast from './Contrast.svg';
import Logo from './Logo.svg';
import Spacing from './Spacing.svg';
import Tint from './Tint.svg';

export default {
  Contrast,
  Logo,
  Spacing,
  Tint,
};
