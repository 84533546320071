// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RenderWindowWithControlBar-module-rootContainer_yvTqc {\n  position: relative;\n}\n\n.RenderWindowWithControlBar-module-renderWindow_Y_G_t {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n}\n\n.RenderWindowWithControlBar-module-control_MzAvM {\n  z-index: 1;\n  position: absolute;\n}\n", "",{"version":3,"sources":["webpack://./Sources/Rendering/Misc/RenderWindowWithControlBar/RenderWindowWithControlBar.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;AACX;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB","sourcesContent":[".rootContainer {\n  position: relative;\n}\n\n.renderWindow {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n}\n\n.control {\n  z-index: 1;\n  position: absolute;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rootContainer": "RenderWindowWithControlBar-module-rootContainer_yvTqc",
	"renderWindow": "RenderWindowWithControlBar-module-renderWindow_Y_G_t",
	"control": "RenderWindowWithControlBar-module-control_MzAvM"
};
export default ___CSS_LOADER_EXPORT___;
