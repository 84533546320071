import Manipulators from './Manipulators';
import Misc from './Misc';
import Style from './Style';
import UI from './UI';
import Widgets from './Widgets';

export default {
  Manipulators,
  Misc,
  Style,
  UI,
  Widgets,
};
