export const WidgetState = {
  START: 0,
  DEFINE: 1,
  MANIPULATE: 2,
  ACTIVE: 3,
};

export default {
  WidgetState,
};
